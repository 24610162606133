<template>
  <v-text-field
    ref="inputField"
    :value="displayValue"
    :label="label + ' ' + currencySymbol"
    :rules="rules"
    :readonly="readonly"
    type="text"
    inputmode="decimal"
    hide-spin-buttons
    filled
    outlined
    dense
    class="custom-text-field"
    @input="handleInput"
    @keydown="handleKeydown"
    @blur="handleBlur"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: "CurrencyInput",

  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    currencySymbol: {
      type: String,
      default: "DZD",
    },
    locale: {
      type: String,
      default: "fr-FR",
    },
  },

  data() {
    return {
      lastCursorPosition: 0,
    };
  },

  computed: {
    displayValue() {
      return this.formatCurrency(this.value);
    },
  },

  methods: {
    formatCurrency(value) {
      if (value === null || value === "") return "";

      const parsed = this.parseCurrency(value);
      if (isNaN(parsed)) return value; // If invalid, return as-is

      return new Intl.NumberFormat(this.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(parsed);
    },

    parseCurrency(value) {
      if (!value && value !== 0) return "";

      let cleanValue = value
        .toString()
        .replace(/\s/g, "") // Remove spaces
        .replace(/[^\d.,]/g, "") // Keep only numbers, dots, and commas
        .replace(",", "."); // Convert comma to dot for decimals

      return parseFloat(cleanValue);
    },

    handleInput(event) {
      const rawValue = event.target.value;
      const numericValue = this.parseCurrency(rawValue);
      const formattedValue = this.formatCurrency(numericValue);

      // Preserve cursor position
      const cursorPosition = event.target.selectionStart;
      this.$nextTick(() => {
        event.target.value = formattedValue;
        event.target.setSelectionRange(cursorPosition, cursorPosition);
      });

      this.$emit("input", numericValue); // Emit correct numeric value
    },

    handleBlur(event) {
      this.$emit("input", this.parseCurrency(event.target.value)); // Save numeric value
    },

    handleKeydown(event) {
      // Save cursor position before formatting
      this.lastCursorPosition = event.target.selectionStart;
    },
  },
};
</script>

<style scoped>
.custom-text-field ::v-deep input {
  text-align: right !important;
  font-weight: bold !important;
}
</style>
